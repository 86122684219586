import React, { useState } from 'react';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MobileMenu } from './Mobilemenu';

const NavBar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Box>
      <MuiAppBar position='static' color='transparent' elevation={0}>
        <Toolbar>
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <Typography variant='h6' sx={{ ml: 1 }}>
              AJALAX
            </Typography>
          </Box>

          {/* Mobile Menu Toggle */}
          <IconButton
            edge='end'
            color='inherit'
            aria-label='menu'
            onClick={() => setMobileMenuOpen(true)}
            sx={{ ml: 'auto' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>

      {/* Mobile Menu */}
      <MobileMenu
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        onCreatePost={() => {
          navigate('/create-listing');
          setMobileMenuOpen(false);
        }}
      />
    </Box>
  );
};

export default NavBar;
