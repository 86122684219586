import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  TextField,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import {
  Lock,
  Send as SendIcon,
  Favorite as FavoriteIcon,
  Reply as ReplyIcon,
  ArrowBack,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ChatMessage,
  Listing,
  ListingMember,
  ListMemberDetails,
} from '../types';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  where,
  query,
  addDoc,
  setDoc,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import { db } from '../services/firebase';
import firebase from 'firebase/compat/app';
import '../services/firebase';
import moment from 'moment';
import InstagramIcon from '@mui/icons-material/Instagram';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import NavBar from './NavBar';

const MoveChatComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [move, setMove] = React.useState<Listing>();
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const [members, setMembers] = React.useState<{
    [key: string]: ListMemberDetails;
  }>({});
  const [requestedToJoin, setRequestedToJoin] = useState(false);
  const { id } = useParams();
  const [uid, setUid] = useState('');
  const [newMessage, setNewMessage] = useState('');

  const [activeTab, setActiveTab] = useState<'chat' | 'pending'>('pending');

  // Then update it when members data changes
  useEffect(() => {
    if (members[uid]?.status === 'active') {
      setActiveTab('chat');
    }
  }, [members, uid]);
  useEffect(() => {
    const fetchMove = async () => {
      const docSnap = await getDoc(doc(db, 'listings', id || ''));
      if (docSnap.exists()) {
        const data = docSnap.data();
        setMove(data as Listing);
      } else {
        console.log('No such document!');
      }
    };
    fetchMove();
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    if (move) {
      document.title = move.title;

      // Get move members
      const q = query(
        collection(db, 'listing_members'),
        where('listingId', '==', id)
      );
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach(async (docSnapshot) => {
          // Fetch user details
          const listMember = { ...docSnapshot.data() } as ListingMember;
          const docSnap = await getDoc(doc(db, 'users', listMember.userId));
          if (docSnap.exists()) {
            const data = docSnap.data();
            setMembers((prev) => ({
              ...prev,
              [listMember.userId]: {
                id: docSnap.id,
                displayName: data.displayName,
                avatar: data.avatar,
                role: listMember.role,
                status: listMember.status,
                instagram: data.instagram,
              },
            }));
            setIsLoading(false);
          } else {
            console.log('No such document!');
          }
        });
      });
    }
  }, [move]);

  const handleJoinRequestClick = async (listingId: string) => {
    const listData = {
      listingId: id,
      userId: uid,
      role: 'member',
      status: 'pending',
      joinedOn: new Date().toISOString(),
    };
    await addDoc(collection(db, 'listing_members'), listData);
    setRequestedToJoin(true);
  };

  const getTotalActiveMembers = () => {
    return Object.keys(members).filter(
      (key) => members[key].status === 'active'
    ).length;
  };

  const handleSendMessage = () => {
    // Add message to the database
    const currentMember = members[uid];
    const newMessageData = {
      author: uid,
      listingId: id,
      message: newMessage,
      sentAt: new Date().toISOString(),
    };
    const messageRef = collection(db, 'messages');
    addDoc(messageRef, newMessageData);
    //setMessages((prev) => [...prev, newMessageData]);

    setNewMessage('');
  };
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (!id) return;
    if (Object.keys(members).length === 0) return;

    const unsubscribe = listenToCollection(id);
    return () => unsubscribe();
  }, [id, members]);

  function listenToCollection(listingId: string) {
    const collectionRef = collection(db, 'messages');
    const q = query(
      collectionRef,
      where('listingId', '==', listingId),
      orderBy('sentAt', 'asc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messageData: ChatMessage[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const author = members[data.author] as ListMemberDetails;
        return {
          id: doc.id,
          author,
          message: data.message,
        };
      });
      setMessages(messageData);
    });

    // Return the unsubscribe function to stop listening when needed
    return unsubscribe;
  }

  const handleApproveMember = async (memberId: string, status: string) => {
    try {
      // query the listing_members collection for the member using the listingId and userId
      const q = query(
        collection(db, 'listing_members'),
        where('listingId', '==', id),
        where('userId', '==', memberId)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (docSnapshot) => {
        const memberRef = doc(db, 'listing_members', docSnapshot.id);
        await setDoc(memberRef, { status }, { merge: true });
      });
      setMembers((prev) => ({
        ...prev,
        [memberId]: {
          ...prev[memberId],
          status,
        },
      }));
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const hasSpace = () => {
    const totalSpaceAvailable = move?.spots || 100;
    return getTotalActiveMembers() < totalSpaceAvailable;
  };

  return (
    <Box sx={{ backgroundColor: '#f5f7fb', minHeight: '100vh' }}>
      <NavBar />

      <Box p={3}>
        {/* back button */}
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
          variant='text'
          color='inherit'
          sx={{
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 'normal',
            letterSpacing: '0px',
          }}
        >
          Back
        </Button>

        {/* Details Section */}
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            p: 3,
            boxShadow: '0 2px 20px rgba(0,0,0,0.05)',
          }}
        >
          {/* Title and Join Button Row */}
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mb={3}
          >
            <Typography
              variant='h5'
              sx={{
                fontWeight: 700,
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {move?.title}
            </Typography>
            {!requestedToJoin &&
              uid &&
              Object.keys(members).length > 0 &&
              !members[uid] &&
              hasSpace() && (
                <Button
                  onClick={() => handleJoinRequestClick(id || '')}
                  variant='contained'
                  sx={{
                    borderRadius: '28px',
                    px: 4,
                    py: 1,
                    textTransform: 'none',
                    background:
                      'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 10px 2px rgba(33, 203, 243, .4)',
                    },
                    transition: 'all 0.2s',
                  }}
                >
                  Request to join
                </Button>
              )}
            {(members[uid]?.status === 'pending' || requestedToJoin) && (
              <Button
                variant='outlined'
                sx={{
                  borderRadius: '28px',
                  px: 4,
                  py: 1,
                  textTransform: 'none',
                  borderColor: '#ffa726',
                  color: '#ffa726',
                  '&:hover': {
                    borderColor: '#fb8c00',
                    backgroundColor: 'rgba(255, 167, 38, 0.04)',
                  },
                }}
              >
                Pending
              </Button>
            )}
          </Box>

          {/* Description */}
          <Typography
            variant='body1'
            sx={{
              color: '#546e7a',
              mb: 3,
              lineHeight: 1.7,
            }}
          >
            {move?.description}
          </Typography>

          {/* Info Cards */}
          <Box
            display='flex'
            gap={2}
            flexWrap='wrap'
            sx={{
              '& > div': {
                flex: '1 1 auto',
                minWidth: '180px',
                maxWidth: 'calc(33.333% - 16px)',
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: '#f8fafd',
                borderRadius: '12px',
                p: 2,
                border: '1px solid #e3f2fd',
              }}
            >
              <Typography variant='subtitle2' color='#90a4ae' gutterBottom>
                Spots filled
              </Typography>
              <Typography variant='h6' color='#1976d2' fontWeight={600}>
                {move?.spots
                  ? `${getTotalActiveMembers()}/${move?.spots}`
                  : getTotalActiveMembers()}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: '#f8fafd',
                borderRadius: '12px',
                p: 2,
                border: '1px solid #e3f2fd',
              }}
            >
              <Typography variant='subtitle2' color='#90a4ae' gutterBottom>
                Duration
              </Typography>
              <Typography variant='h6' color='#1976d2' fontWeight={600}>
                {moment(move?.startDate).format('MMM D')} -{' '}
                {moment(move?.endDate).format('MMM D')}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: '#f8fafd',
                borderRadius: '12px',
                p: 2,
                border: '1px solid #e3f2fd',
              }}
            >
              <Typography variant='subtitle2' color='#90a4ae' gutterBottom>
                Location
              </Typography>
              <Typography variant='h6' color='#1976d2' fontWeight={600}>
                {move?.location}
              </Typography>
            </Box>
          </Box>
        </Box>

        {isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='calc(100vh - 64px)'
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Tabs Section */}
            <Box mt={3}>
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 2,
                  '& .MuiTab-root': {
                    minHeight: 64,
                    fontWeight: 600,
                    textTransform: 'none',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3',
                  },
                }}
              >
                <Tab
                  label={
                    <Box display='flex' alignItems='center' gap={1}>
                      Chat{' '}
                      {members[uid]?.status !== 'active' && (
                        <Lock fontSize='small' />
                      )}
                    </Box>
                  }
                  value='chat'
                />
                <Tab label='Members' value='pending' />
              </Tabs>
            </Box>

            {/* Chat Section */}
            {activeTab === 'chat' && members[uid]?.status === 'active' ? (
              <Box
                mt={3}
                sx={{
                  maxHeight: 'calc(80vh - 250px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#555',
                    },
                  },
                }}
                ref={chatContainerRef}
              >
                <Box mb={10}>
                  {messages.length === 0 ? (
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      height='200px'
                    >
                      Start to chat
                    </Box>
                  ) : (
                    messages.map((message, index) => (
                      <Box
                        key={index}
                        display='flex'
                        alignItems='flex-start'
                        gap={2}
                        mb={3}
                        sx={{
                          flexDirection:
                            message.author.id === uid ? 'row-reverse' : 'row',
                          animation: 'fadeIn 0.3s ease-in',
                          '@keyframes fadeIn': {
                            '0%': { opacity: 0, transform: 'translateY(10px)' },
                            '100%': { opacity: 1, transform: 'translateY(0)' },
                          },
                        }}
                      >
                        <Box position='relative'>
                          <Avatar
                            src={message?.author?.avatar}
                            sx={{
                              width: 40,
                              height: 40,
                              border: '2px solid #fff',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: 0,
                              right: 0,
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              backgroundColor: '#44b700',
                              border: '2px solid white',
                            }}
                          />
                        </Box>

                        <Box sx={{ maxWidth: '70%' }}>
                          <Typography
                            variant='body2'
                            fontWeight={600}
                            sx={{
                              mb: 0.5,
                              color:
                                message.author.id === uid
                                  ? '#2196F3'
                                  : '#424242',
                            }}
                          >
                            {message.author.displayName}
                          </Typography>

                          <Box
                            sx={{
                              backgroundColor:
                                message.author.id === uid
                                  ? '#2196F3'
                                  : '#f5f5f5',
                              color:
                                message.author.id === uid ? 'white' : 'black',
                              padding: '12px 16px',
                              borderRadius: '18px',
                              boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                            }}
                          >
                            <Typography variant='body2'>
                              {message.message}
                            </Typography>
                          </Box>

                          <Box
                            display='flex'
                            justifyContent={
                              message.author.id === uid
                                ? 'flex-end'
                                : 'flex-start'
                            }
                            mt={0.5}
                            gap={1}
                          >
                            {/* add time  */}
                            {/* <Typography variant='caption' sx={{ opacity: 0.6 }}>
                          {moment(message.sentAt).format('h:mm A')}
                        </Typography> */}

                            <Box
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                '&:hover': { opacity: 1 },
                              }}
                            >
                              <IconButton size='small'>
                                <FavoriteIcon
                                  fontSize='small'
                                  sx={{ color: '#f06292' }}
                                />
                              </IconButton>
                              <IconButton size='small'>
                                <ReplyIcon
                                  fontSize='small'
                                  sx={{ color: '#78909c' }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>

                {/* Message Input */}
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'white',
                    borderTop: '1px solid rgba(0,0,0,0.1)',
                    padding: '16px 24px',
                    zIndex: 1000,
                  }}
                >
                  <TextField
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    variant='outlined'
                    placeholder='Type your message...'
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '24px',
                        backgroundColor: '#f5f7fb',
                        '&:hover': {
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        },
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#2196F3',
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          color='primary'
                          onClick={handleSendMessage}
                          disabled={!newMessage.trim()}
                          sx={{
                            '&:hover': {
                              transform: 'scale(1.1)',
                              backgroundColor: 'rgba(33, 150, 243, 0.1)',
                            },
                            transition: 'all 0.2s',
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              </Box>
            ) : (
              activeTab === 'chat' && (
                <Box
                  mt={3}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 2,
                    p: 4,
                    textAlign: 'center',
                  }}
                >
                  <Lock sx={{ fontSize: 48, color: '#bdbdbd', mb: 2 }} />
                  <Typography variant='h6' sx={{ color: '#424242', mb: 1 }}>
                    Chat is only available to accepted members
                  </Typography>
                  <Typography variant='body2' color='#757575'>
                    Your request is pending approval from the admin
                  </Typography>
                </Box>
              )
            )}

            {/* Members Section */}
            {activeTab === 'pending' && (
              <Box
                mt={3}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 2,
                  p: 3,
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                }}
              >
                <Typography
                  variant='h6'
                  sx={{ mb: 2, color: '#1a237e', fontWeight: 600 }}
                >
                  Members
                </Typography>
                <List>
                  {Object.values(members)
                    .filter((member) => member.status !== 'rejected')
                    .map((member, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          borderBottom: '1px solid #f5f5f5',
                          '&:last-child': {
                            borderBottom: 'none',
                          },
                          py: 2,
                          transition: 'all 0.2s',
                          '&:hover': {
                            backgroundColor: '#f8fafd',
                          },
                        }}
                      >
                        <ListItemAvatar>
                          <Box position='relative'>
                            <Avatar
                              src={member.avatar}
                              sx={{
                                width: 48,
                                height: 48,
                                border: '2px solid #fff',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                            />
                            <Box
                              sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                backgroundColor:
                                  member.status === 'active'
                                    ? '#44b700'
                                    : '#ffa726',
                                border: '2px solid white',
                              }}
                            />
                          </Box>
                        </ListItemAvatar>

                        <Box style={{ width: '250px' }}>
                          <ListItemText
                            primary={
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                }}
                              >
                                <Typography sx={{ fontWeight: 600 }}>
                                  {member.displayName}
                                </Typography>
                                <InstagramIcon
                                  sx={{
                                    color: '#e1306c',
                                    cursor: 'pointer',
                                    fontSize: 20,
                                    '&:hover': {
                                      transform: 'scale(1.1)',
                                    },
                                    transition: 'transform 0.2s',
                                  }}
                                  onClick={() =>
                                    window.open(
                                      'https://instagram.com/' +
                                        member.instagram
                                    )
                                  }
                                />
                              </Box>
                            }
                          />
                        </Box>

                        {members[uid]?.role === 'admin' && (
                          <Box
                            display='flex'
                            justifyContent='flex-end'
                            flex={1}
                          >
                            {member.status === 'pending' && (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleApproveMember(member.id, 'active')
                                  }
                                  sx={{
                                    color: '#4caf50',
                                    '&:hover': {
                                      backgroundColor: 'rgba(76, 175, 80, 0.1)',
                                    },
                                  }}
                                >
                                  <CheckCircleOutlineIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleApproveMember(member.id, 'rejected')
                                  }
                                  sx={{
                                    color: '#f44336',
                                    '&:hover': {
                                      backgroundColor: 'rgba(244, 67, 54, 0.1)',
                                    },
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        )}
                      </ListItem>
                    ))}
                </List>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MoveChatComponent;
