import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import '../services/firebase';
import HomeScreen from '../components/Homescreen';

const Home = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (!user) {
          navigate('/login');
        }
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  if (!isSignedIn) return null;

  return <HomeScreen />;
};

export default Home;
