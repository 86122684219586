// components/MobileMenu.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Add as AddIcon,
  Person as PersonIcon,
  Map as MapIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import firebase from 'firebase/compat/app';
import '../services/firebase';

interface MobileMenuProps {
  open: boolean;
  onClose: () => void;
  onCreatePost: () => void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  open,
  onClose,
  onCreatePost,
}) => {
  const navigate = useNavigate();

  const handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate('/login');
      });
  };
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <List sx={{ width: 250 }}>
        <ListItemButton onClick={onCreatePost}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary='Create Post' />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => {
            navigate('/profile');
            onClose();
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary='Profile' />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate('/my-moves');
            onClose();
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='My Moves' />
        </ListItemButton>
      </List>
      <button
        style={{
          margin: 10,
          padding: 10,
          borderColor: 'red',
          color: 'red',
          borderWidth: 1,
          cursor: 'pointer',
          backgroundColor: 'transparent',
        }}
        onClick={handleSignout}
      >
        Sign Out
      </button>
    </Drawer>
  );
};
