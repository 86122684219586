// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import { firebaseConfigProd } from './firebase.production';
import { firebaseConfigStaging } from './firebase.staging';

const firebaseConfig =
  process.env.REACT_APP_ENV === 'production'
    ? firebaseConfigProd
    : firebaseConfigStaging;

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
