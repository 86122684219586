import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Avatar,
  Card,
  CardContent,
  Chip,
  Stack,
  useTheme,
  useMediaQuery,
  Link,
} from '@mui/material';
import {
  AttachMoney as MoneyIcon,
  CalendarToday as CalendarIcon,
  Group as GroupIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { MobileMenu } from './Mobilemenu';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from 'firebase/firestore';
import { db } from '../services/firebase';
import '../services/firebase';
import NavBar from './NavBar';
import Navigationbottom from './Navigationbottom';
import moment from 'moment';
import { Listing, ListingMember, User } from '../types';
import InstagramIcon from '@mui/icons-material/Instagram';
import NoContentAvailable from './Emptycontent';

const HomeScreen: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    'housing' | 'movement' | 'services' | 'profile'
  >('movement');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [servicePosts, setServicePosts] = useState<Listing[]>([]);
  const [housingPosts, setHousingPosts] = useState<Listing[]>([]);
  const [movementPosts, setMovementPosts] = useState<Listing[]>([]);
  const [myMoves, setMyMoves] = useState<{ [key: string]: ListingMember }>({});
  const [users, setUsers] = useState<{ [key: string]: User }>({});
  const [uid, setUid] = useState('');
  let uids: string[] = [];

  async function fetchUsersInBatch(uids: string[]) {
    const userQuery = query(
      collection(db, 'users'),
      where('__name__', 'in', uids)
    );
    const userSnapshots = await getDocs(userQuery);
    const users: { [key: string]: any } = {};
    userSnapshots.forEach((doc) => {
      users[doc.id] = doc.data();
    });
    return users;
  }

  const handleBottomNavigation = (
    event: React.SyntheticEvent,
    newValue: 'housing' | 'movement' | 'profile' | 'services'
  ) => {
    if (newValue === 'profile') {
      navigate('/profile');
    } else {
      setActiveTab(newValue);
    }
  };
  // Function to fetch filtered documents
  async function getFilteredListings(fieldName: string, fieldValue: string) {
    try {
      const colRef = collection(db, 'listings'); // Replace with your collection name

      // Create a query to filter by a specific field
      const q = query(colRef, where(fieldName, '==', fieldValue));

      // Execute the query and get the documents
      const querySnapshot = await getDocs(q);

      // Process the documents
      const documents: Listing[] = [];
      querySnapshot.forEach((doc) => {
        uids.push(doc.data().createdBy);
        documents.push({ ...doc.data(), id: doc.id } as Listing);
      });
      return documents;
    } catch (error) {
      console.error(
        'Error fetching filtered documents:',
        (error as Error).message
      );
    }
  }

  useEffect(() => {
    document.title = 'Ajalaxp - TravelCompanion';
    const fetchPosts = async () => {
      uids = [];
      const housingCollection = await getFilteredListings(
        'category',
        'housing'
      );
      const movementCollection = await getFilteredListings(
        'category',
        'movement'
      );
      const servicesCollection = await getFilteredListings(
        'category',
        'services'
      );
      if (housingCollection) setHousingPosts(housingCollection);
      if (movementCollection) setMovementPosts(movementCollection);
      if (servicesCollection) setServicePosts(servicesCollection);
      const users = await fetchUsersInBatch(uids);
      setUsers(users);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    const fetchMyMoves = async () => {
      const q = query(
        collection(db, 'listing_members'),
        where('userId', '==', uid)
      );
      const querySnapshot = await getDocs(q);
      const moves: { [key: string]: ListingMember } = {};
      querySnapshot.forEach((doc) => {
        const data = { ...doc.data() } as unknown as ListingMember;
        moves[data.listingId] = data;
      });
      setMyMoves(moves);
    };
    fetchMyMoves();
  }, [uid]);

  useEffect(() => {
    // Get user data from users collection
    if (!uid) return;
    const fetchProfile = async () => {
      const docSnap = await getDoc(doc(db, 'users', uid));
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (!data.instagram || !data.displayName) {
          navigate('/profile');
        }
      } else {
        navigate('/profile');
      }
    };
    fetchProfile();
  }, [uid]);
  // Function to get "x ago"
  function getTimeAgo(timestamp: string) {
    return moment(timestamp).fromNow(); // Calculates "x ago"
  }

  const handlePostClick = (postId: string) => {
    navigate(`/listing-chat/${postId}`);
  };

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <NavBar />

      {/* Mobile Menu */}
      {isMobile && (
        <MobileMenu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          onCreatePost={() => {
            navigate('/create-listing');
            setMobileMenuOpen(false);
          }}
        />
      )}

      {/* Main Content */}
      <Container maxWidth='lg' sx={{ py: 4, height: 'calc(100% - 100px)' }}>
        {/* Welcome Banner */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper
            sx={{
              background: 'linear-gradient(135deg, #4B39EF 0%, #6200EA 100%)',
              color: 'white',
              p: 4,
              mb: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant='body1'>Welcome back,</Typography>
            <Typography variant='h4' sx={{ fontWeight: 'bold', mb: 1 }}>
              {firebase.auth().currentUser?.displayName}
            </Typography>
            <Typography variant='body2' sx={{ color: '#E0E0E0' }}>
              Find your perfect travel companions
            </Typography>
          </Paper>
        </motion.div>

        {/* Posts Section */}
        <Box>
          {/* handle empty */}
          {housingPosts.length === 0 ||
            movementPosts.length === 0 ||
            (servicePosts.length === 0 && (
              <NoContentAvailable message={activeTab} />
            ))}
          <Typography variant='h5' sx={{ mb: 3, fontWeight: 'bold' }}>
            {activeTab === 'housing'
              ? 'Housing'
              : activeTab === 'movement'
                ? 'Movement'
                : 'Services'}{' '}
            Posts
          </Typography>

          <Stack spacing={2}>
            {(activeTab === 'housing'
              ? housingPosts
              : activeTab === 'movement'
                ? movementPosts
                : servicePosts
            ).map((post, index) => (
              <motion.div
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Avatar src={users[post.createdBy]?.avatar} />
                        <Box>
                          <Typography
                            variant='subtitle1'
                            sx={{ fontWeight: 'bold' }}
                          >
                            {post.title}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              color: 'text.secondary',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography variant='body2' color='text.secondary'>
                              listed by{' '}
                              <Link
                                style={{ textDecoration: 'none' }}
                                href={
                                  'https://instagram.com/' +
                                  users[post.createdBy]?.instagram
                                }
                                target='_blank'
                                rel='noopener'
                              >
                                {users[post.createdBy]?.displayName}
                              </Link>
                            </Typography>
                            <InstagramIcon
                              style={{
                                marginLeft: -5,
                                color: 'Highlight',
                                cursor: 'pointer',
                              }}
                              fontSize='small'
                            />
                          </Box>
                          <Typography variant='body2' color='text.secondary'>
                            {getTimeAgo(post.createdOn)}
                          </Typography>
                        </Box>
                      </Box>
                      <Chip
                        label={post.location}
                        sx={{
                          bgcolor:
                            activeTab === 'housing'
                              ? '#E3F2FD'
                              : activeTab === 'movement'
                                ? '#FFF3E0'
                                : '#F3E5F5',
                          color:
                            activeTab === 'housing'
                              ? '#1565C0'
                              : activeTab === 'movement'
                                ? '#EF6C00'
                                : '#673AB7',
                        }}
                      />
                    </Box>

                    <Typography variant='body1' sx={{ mb: 2 }}>
                      {post.description}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Stack direction='row' spacing={2}>
                        {post.price && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <MoneyIcon fontSize='small' color='action' />
                            <Typography variant='body2' color='text.secondary'>
                              {post.price}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <CalendarIcon fontSize='small' color='action' />
                          <Typography variant='body2' color='text.secondary'>
                            {moment(post.startDate).format('MMM D')} -{' '}
                            {moment(post.endDate).format('MMM D')}
                          </Typography>
                        </Box>
                        {post.spots && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <GroupIcon fontSize='small' color='action' />
                            <Typography variant='body2' color='text.secondary'>
                              {post.spots}
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                      <Button
                        onClick={() => handlePostClick(post.id)}
                        variant='contained'
                        sx={{
                          borderRadius: 8,
                          px: 1,
                        }}
                      >
                        View
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </Stack>
        </Box>
      </Container>

      <Navigationbottom value={activeTab} onChange={handleBottomNavigation} />
    </Box>
  );
};

export default HomeScreen;
