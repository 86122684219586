import React from 'react';
import CreateListing from '../components/CreateListing';

const Create = () => {
  return (
    <div>
      <CreateListing />
    </div>
  );
};

export default Create;
