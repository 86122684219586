import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Avatar,
  Card,
  CardContent,
  Chip,
  Stack,
  useTheme,
  useMediaQuery,
  Link,
  IconButton,
} from '@mui/material';
import {
  Home as HomeIcon,
  DirectionsWalk as WalkIcon,
  AttachMoney as MoneyIcon,
  CalendarToday as CalendarIcon,
  Group as GroupIcon,
  Menu as MenuIcon,
  Add as AddIcon,
  AccountCircle as ProfileIcon,
  SupervisorAccount as AllPostsIcon,
  Inventory2 as MyPostsIcon,
  Delete,
  ArrowBack,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { MobileMenu } from './Mobilemenu';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { db } from '../services/firebase';
import '../services/firebase';
import NavBar from './NavBar';
import Navigationbottom from './Navigationbottom';
import moment from 'moment';
import { Listing, ListingMember, User } from '../types';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NoContentAvailable from './Emptycontent';

const MyMovesView: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    'housing' | 'movement' | 'services' | 'profile'
  >('movement');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [housingPosts, setHousingPosts] = useState<Listing[]>([]);
  const [myMoves, setMyMoves] = useState<{ [key: string]: ListingMember }>({});
  const [users, setUsers] = useState<{ [key: string]: User }>({});
  const [uid, setUid] = useState('');
  let uids: string[] = [];

  async function fetchUsersInBatch(uids: string[]) {
    const userQuery = query(
      collection(db, 'users'),
      where('__name__', 'in', uids)
    );
    const userSnapshots = await getDocs(userQuery);
    const users: { [key: string]: any } = {};
    userSnapshots.forEach((doc) => {
      users[doc.id] = doc.data();
    });
    return users;
  }

  // Function to fetch filtered documents
  async function getFilteredListings(fieldName: string, fieldValue: string) {
    try {
      const colRef = collection(db, 'listing_members'); // Replace with your collection name

      // Create a query to filter by when the userId is equal to the fieldValue and status is active
      const q = query(
        colRef,
        where('userId', '==', uid),
        where('status', '==', 'active')
      );

      // Execute the query and get the documents
      const querySnapshot = await getDocs(q);

      // Process the documents
      const documents: Listing[] = [];
      const listingIds: string[] = [];
      querySnapshot.forEach((doc) => {
        uids.push(doc.data().userId);
        listingIds.push(doc.data().listingId);
      });

      const listingsQuery = query(
        collection(db, 'listings'),
        where('__name__', 'in', listingIds)
      );
      const listingsSnapshot = await getDocs(listingsQuery);
      listingsSnapshot.forEach((doc) => {
        const data = doc.data() as Listing;
        documents.push({ ...data, id: doc.id });
      });
      return documents;
    } catch (error) {
      console.error(
        'Error fetching filtered documents:',
        (error as Error).message
      );
    }
  }

  useEffect(() => {
    const fetchPosts = async () => {
      uids = [];
      const housingCollection = await getFilteredListings('userId', uid);

      if (housingCollection) setHousingPosts(housingCollection);
      const users = await fetchUsersInBatch(uids);
      setUsers(users);
    };
    if (uid) {
      fetchPosts();
    }
  }, [uid]);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    const fetchMyMoves = async () => {
      const q = query(
        collection(db, 'listing_members'),
        where('userId', '==', uid)
      );
      const querySnapshot = await getDocs(q);
      const moves: { [key: string]: ListingMember } = {};
      querySnapshot.forEach((doc) => {
        const data = { ...doc.data() } as unknown as ListingMember;
        moves[data.listingId] = data;
      });
      setMyMoves(moves);
    };
    fetchMyMoves();
  }, [uid]);

  const handleDeleteMove = async (moveId: string) => {
    if (window.confirm('Are you sure you want to delete this move?')) {
      try {
        await deleteDoc(doc(db, 'listings', moveId));
        // Remove from local state
        setHousingPosts((prevPosts) =>
          prevPosts.filter((post) => post.id !== moveId)
        );
      } catch (error) {
        console.error('Error deleting move:', error);
      }
    }
  };

  // Function to get "x ago"
  function getTimeAgo(timestamp: string) {
    return moment(timestamp).fromNow(); // Calculates "x ago"
  }

  const handlePostClick = (postId: string) => {
    navigate(`/listing-chat/${postId}`);
  };

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <NavBar />

      {/* Mobile Menu */}
      {isMobile && (
        <MobileMenu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          onCreatePost={() => {
            navigate('/create-listing');
            setMobileMenuOpen(false);
          }}
        />
      )}

      {/* Main Content */}
      <Container maxWidth='lg' sx={{ py: 4, height: 'calc(100% - 100px)' }}>
        {/* back button */}
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
          variant='text'
          color='inherit'
          sx={{
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 'normal',
            letterSpacing: '0px',
            marginBottom: '8px',
          }}
        >
          Back
        </Button>
        {/* Welcome Banner */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper
            sx={{
              background: 'linear-gradient(135deg, #4B39EF 0%, #6200EA 100%)',
              color: 'white',
              p: 4,
              mb: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant='body1'>My moves</Typography>
          </Paper>
        </motion.div>

        {/* Posts Section */}
        <Box>
          {housingPosts.length === 0 ? (
            <NoContentAvailable message='moves' />
          ) : (
            <Stack spacing={2}>
              {housingPosts.map((post, index) => (
                <motion.div
                  key={post.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                        >
                          <Avatar src={users[post.createdBy]?.avatar} />
                          <Box>
                            <Stack
                              direction='row'
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              <Typography
                                variant='subtitle1'
                                sx={{ fontWeight: 'bold' }}
                              >
                                {post.title}
                              </Typography>
                              {post.createdBy === uid && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // Add your delete logic here
                                    handleDeleteMove(post.id);
                                  }}
                                  sx={{
                                    ml: 1,
                                    color: '#f44336',
                                    '&:hover': {
                                      backgroundColor: 'rgba(244, 67, 54, 0.1)',
                                    },
                                  }}
                                >
                                  <Delete fontSize='medium' />
                                </IconButton>
                              )}
                            </Stack>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                color: 'text.secondary',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                listed by{' '}
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  href={
                                    'https://instagram.com/' +
                                    users[post.createdBy]?.instagram
                                  }
                                  target='_blank'
                                  rel='noopener'
                                >
                                  {users[post.createdBy]?.displayName}
                                </Link>
                              </Typography>
                              <InstagramIcon
                                style={{
                                  marginLeft: -5,
                                  color: 'Highlight',
                                  cursor: 'pointer',
                                }}
                                fontSize='small'
                              />
                            </Box>
                            <Typography variant='body2' color='text.secondary'>
                              {getTimeAgo(post.createdOn)}
                            </Typography>
                          </Box>
                        </Box>
                        <Chip
                          label={post.location}
                          sx={{
                            bgcolor:
                              post.category === 'housing'
                                ? '#E3F2FD'
                                : post.category === 'movement'
                                  ? '#FFF3E0'
                                  : '#F3E5F5',
                            color:
                              post.category === 'housing'
                                ? '#1565C0'
                                : post.category === 'movement'
                                  ? '#EF6C00'
                                  : '#673AB7',
                          }}
                        />
                      </Box>

                      <Typography variant='body1' sx={{ mb: 2 }}>
                        {post.description}
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Stack direction='row' spacing={2}>
                          {post.price && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <MoneyIcon fontSize='small' color='action' />
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                {post.price}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <CalendarIcon fontSize='small' color='action' />
                            <Typography variant='body2' color='text.secondary'>
                              {moment(post.startDate).format('MMM D')} -{' '}
                              {moment(post.endDate).format('MMM D')}
                            </Typography>
                          </Box>
                          {post.spots && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <GroupIcon fontSize='small' color='action' />
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                {post.spots}
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                        <Button
                          onClick={() => handlePostClick(post.id)}
                          variant='contained'
                          sx={{
                            borderRadius: 8,
                            px: 1,
                          }}
                        >
                          View
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </Stack>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default MyMovesView;
