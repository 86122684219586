// components/Profile.tsx
import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Stack,
  Snackbar,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import { ArrowBack, Edit as EditIcon } from '@mui/icons-material';
import firebase from 'firebase/compat/app';
import { getAuth, updateProfile } from 'firebase/auth';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import AppBar from './NavBar';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';

interface UserProfile {
  displayName: string;
  born: string;
  flyingFrom: string;
  instagramHandle: string;
  photoURL: string | null;
}

export const Profile: React.FC = () => {
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uid, setUid] = useState('');
  const [profile, setProfile] = useState<UserProfile>({
    displayName: '',
    born: '',
    flyingFrom: '',
    instagramHandle: '',
    photoURL: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    type: 'success' as 'success' | 'error',
  });

  const getUserProfile = async (uid: string) => {
    const docSnap = await getDoc(doc(db, 'users', uid));
    if (docSnap.exists()) {
      const data = docSnap.data();
      setProfile((prev) => ({
        ...prev,
        born: data.born,
        flyingFrom: data.flyingFrom,
        instagramHandle: data.instagram,
      }));
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setProfile({
            displayName: user.displayName || '',
            born: '',
            flyingFrom: '',
            instagramHandle: '',
            photoURL: user.photoURL,
          });
          setUid(user.uid);
          getUserProfile(user.uid);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // Function to update the display name
  async function updateAuthProfile(newDisplayName: string) {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Update the user's profile
        await updateProfile(user, { displayName: newDisplayName });
      } else {
        console.log('No user is signed in.');
      }
    } catch (error) {
      console.error('Error updating profile:', (error as any).message);
    }
  }

  const handleSave = async () => {
    // Update the user's profile
    updateAuthProfile(profile.displayName);
    try {
      const docRef = doc(db, 'users', uid);
      const data = {
        displayName: profile.displayName,
        avatar: profile.photoURL,
        born: profile.born,
        flyingFrom: profile.flyingFrom,
        instagram: profile.instagramHandle,
      };
      setDoc(docRef, data)
        .then(() => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
    setIsEditing(false);
    setNotification({
      open: true,
      message: 'Profile updated successfully!',
      type: 'success',
    });
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth='md'>
      <NavBar />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Button
          startIcon={<ArrowBack />}
          onClick={handleBackClick}
          variant='text'
          color='inherit'
          sx={{
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 'normal',
            letterSpacing: '0px',
          }}
        >
          Back
        </Button>
        <Paper sx={{ p: 4, mt: 4, borderRadius: 2 }}>
          {profile.instagramHandle === '' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4,
                p: 2,
                bgcolor: 'warning.light',
                borderRadius: 1,
              }}
            >
              <Typography variant='body1' color='warning.dark'>
                Update profile and add Instagram handle to get started
              </Typography>
            </Box>
          ) : null}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
            <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
              My Profile
            </Typography>
            <Button
              variant={isEditing ? 'contained' : 'outlined'}
              onClick={() => (isEditing ? handleSave() : setIsEditing(true))}
              startIcon={isEditing ? null : <EditIcon />}
            >
              {isEditing ? 'Save Changes' : 'Edit Profile'}
            </Button>
          </Box>

          <Stack spacing={4}>
            {/* Profile Image */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <motion.div whileHover={{ scale: 1.05 }}>
                <Avatar
                  src={profile.photoURL || '/placeholder-avatar.png'}
                  sx={{ width: 150, height: 150, cursor: 'pointer' }}
                  onClick={() => fileInputRef.current?.click()}
                />
              </motion.div>
            </Box>

            {/* Profile Fields */}
            <Stack spacing={3}>
              <TextField
                label='Name'
                value={profile.displayName}
                onChange={(e) =>
                  setProfile((prev) => ({
                    ...prev,
                    displayName: e.target.value,
                  }))
                }
                disabled={!isEditing}
                fullWidth
              />
              <TextField
                label='Age'
                type='number'
                value={profile.born}
                onChange={(e) =>
                  setProfile((prev) => ({ ...prev, born: e.target.value }))
                }
                disabled={!isEditing}
                fullWidth
              />
              <TextField
                label='Flying in from'
                value={profile.flyingFrom}
                onChange={(e) =>
                  setProfile((prev) => ({
                    ...prev,
                    flyingFrom: e.target.value,
                  }))
                }
                disabled={!isEditing}
                fullWidth
              />
              <TextField
                label='Instagram handle'
                value={profile.instagramHandle}
                onChange={(e) =>
                  setProfile((prev) => ({
                    ...prev,
                    instagramHandle: e.target.value,
                  }))
                }
                disabled={!isEditing}
                fullWidth
                InputProps={{
                  startAdornment: <Typography>@</Typography>,
                }}
              />
            </Stack>
          </Stack>
        </Paper>
      </motion.div>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          severity={notification.type}
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};
