// src/routes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import { Profile } from './components/Profile';
import Create from './pages/Create';
import Chat from './pages/Chat';
import MyMovesView from './components/MyMoves';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/login' element={<Login />} />
      <Route path='/create-listing' element={<Create />} />
      <Route path='/listing-chat/:id' element={<Chat />} />
      <Route path='/my-moves' element={<MyMovesView />} />
      {/* <Route path="/" element={<Home />} /> */}
    </Routes>
  );
};

export default AppRoutes;
