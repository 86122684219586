import React from 'react';
import { Box, Typography } from '@mui/material';
import { DirectionsWalk as WalkIcon } from '@mui/icons-material';

interface NoContentAvailableProps {
  message?: string;
}

const NoContentAvailable: React.FC<NoContentAvailableProps> = ({
  message = 'content',
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 8,
      }}
    >
      <WalkIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
      <Typography variant='body1' color='text.secondary' mt={2}>
        No {message} available
      </Typography>
    </Box>
  );
};

export default NoContentAvailable;
