import React from 'react';
import {
  Box,
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  useTheme,
} from '@mui/material';
import {
  Home as HomeIcon,
  DirectionsWalk as WalkIcon,
  WatchLater as ServicesIcon,
  AccountCircle as ProfileIcon,
} from '@mui/icons-material';

interface BottomNavigationProps {
  value: 'housing' | 'movement' | 'services' | 'profile';
  onChange: (
    event: React.SyntheticEvent,
    newValue: 'housing' | 'movement' | 'services' | 'profile'
  ) => void;
}

const Navigationbottom: React.FC<BottomNavigationProps> = ({
  value,
  onChange,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <MuiBottomNavigation
        showLabels
        value={value}
        onChange={onChange}
        sx={{ bgcolor: 'background.paper' }}
      >
        <BottomNavigationAction
          label='Movement'
          value='movement'
          icon={<WalkIcon />}
        />
        <BottomNavigationAction
          label='Housing'
          value='housing'
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          label='Services'
          value='services'
          icon={<ServicesIcon />}
        />
        <BottomNavigationAction
          label='My Profile'
          value='profile'
          icon={<ProfileIcon />}
        />
      </MuiBottomNavigation>
    </Box>
  );
};

export default Navigationbottom;
